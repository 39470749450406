/**
 * Colors
 */
$dark: #212121;
$light: #FFF;
$grey-light: #F6F6F6;

/**
 * Spacing
 */
$space:    20px;
$space-xs: $space / 4;
$space-sm: $space / 2;
$space-md: $space * 2;
$space-lg: $space * 3;
$space-xl: $space * 4;

/**
 * Other Variables
 */
$sidebar-max-width: 340px;
$screen-lg: 1024px;

/**
 * Spin Animation
 */
@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}


/**
 * Body Styles
 */
body {
  &.loaded {

    // When loaded, fade the screen to light!
    .app-wrap {
      background-color: $light;
    }
  }
}

/**
 * App Wrap Styles
 */
.app-wrap {
  align-items: center;
  background: $dark;
  display: flex;
  justify-content: center;
  position: relative;
  transition: background-color 0.2s ease-out;
  width: 100vw;
}

/**
 * Powered By
 */
.poweredby-wrap {
  bottom: $space-sm;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  .poweredby, .poweredby a {
    color: $light;
  }

  .poweredby a:hover {
    text-decoration: none;
  }
}

/**
 * Autocomplete Styles
 */
.autocomplete {
  padding: $space-sm $space;
  position: relative;

  .input-clear {
    cursor: pointer;
  }

  input[type=search] {
    // Clears the default 'X' from Internet Explorer
    &::-ms-clear,
    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    // Clears the default 'X' from Chrome
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
      -webkit-appearance: none;
    }
  }

  .autocomplete-dropdown-container {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 100;
    width: 100%;

    .suggestion-item {
      background: $light;
      cursor: pointer;

      &.active {
        background: $grey-light;
      }

      .suggestion {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}

/**
 * Location Styles
 */
.container--location {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-position: center;

  .location-box {
    background: $light;
    border-radius: 15px;
    max-width: 400px;
    margin: $space;
    padding: $space;
    width: 100%;
  }

  .location-box-header {
    padding: $space;
  }

  .logo-wrap {
    align-items: center;
    display: flex;
    margin-bottom: $space;
    justify-content: center;

    .logo {
      max-width: 100%;
      height: auto;
    }
  }

  .autocomplete {
    background: $grey-light;
    border-radius: 2px;
    margin-bottom: $space-sm;
  }

  .or {
    margin-bottom: $space-sm;
  }

  .geolocator {
    padding: $space-sm;

    // Spin icon when fetching location
    &.fetching {
      .icon {
        animation: spin 3s infinite;
      }
    }
  }
}



/**
 * Finder Styles
 */
.container--finder {
  display: flex;
  height: 100%;
  width: 100vw;

  @media screen and (max-width: $screen-lg - 1) {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  @media screen and (min-width: $screen-lg) {
    height: 100vh;
  }

  /**
   * Sidebar
   */
  .sidebar-wrap {
    overflow: hidden;
    width: 100%;

    @media screen and (min-width: $screen-lg) {
      max-width: $sidebar-max-width;
      width: 30%;
    }
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    .padding-box {
      padding: $space;
    }

    .autocomplete {
      padding: $space-sm $space;
    }

    .accounts-list {
      flex-grow: 1;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      padding-bottom: $space-lg;

      @media screen and (min-width: $screen-lg) {
        padding-bottom: 0;
      }

      .account {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        padding: ($space / 1.5) $space;

        .location-icon {
          fill: #cacaca;
          height: 16px;
          left: -2px;
          position: relative;
          top: 3px;
          width: 16px;
        }
      }

      // Line arrow icon up
      .arrow-icon-wrap {
        right: 4px;
      }

      .arrow-icon {
        height: 16px;
        position: relative;
        right: 8px;
      }
    }
  }

  /**
   * Filters
   */
  .filters-wrap {
    margin: 0 !important;

    .filters-toggle-wrap {
      display: flex;
      padding: 0;
      min-height: 0 !important;

      & > div {
        margin: 0;
      }

      .filters-toggle,
      .filters-reset {
        border-radius: 0;
        padding: $space-sm $space;
      }

      .filters-toggle {
        justify-content: left;
        flex: 1 auto;
      }

      .filters-reset {
      }
    }

    .filters {
      background: $grey-light;
      display: block;
      padding: $space;
    }

    .filter {
      margin-bottom: $space-md;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  /**
   * Account Popover
   */
  .account-popover {
    position: fixed;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    background: $light;
    transition: all 0.2s;
    transform: translateX(-100%);
    overflow-y: scroll;
    padding-bottom: $space;
    z-index: 1000;

    @media screen and (min-width: $screen-lg) {
      max-width: $sidebar-max-width;
      width: 30%;
    }

    &.in {
      transform: translateX(0);
    }

    .account-popover-header {
      margin-bottom: $space-sm;

      .back {
        justify-content: left;
        padding: $space;
        border-radius: 0;
      }
    }

    .account-details {
      .address, .proximity {
        margin-bottom: $space;
      }

      .proximity {
        .location-icon {
          position: relative;
          top: 3px;
        }
      }
    }

    .account-products {
      .note {
        font-style: italic;
      }

      .brand {
        background: $grey-light;
        display: flex;
        flex-direction: row;
        margin-bottom: 1px;
        padding: $space-sm $space;
        justify-content: space-between;
      }

      .formats {
        display: flex;

        .format {
          cursor: pointer;
          position: relative;
          text-align: center;
          top: 2px;
          width: 20px;
        }

        .format-icon {
          height: 16px;
        }
      }
    }

  }

  /**
   * Map Styles
   */
  .map {
    flex-grow: 1;
    height: 100%;

    @media screen and (max-width: $screen-lg - 1) {
      padding-bottom: $space-lg;
    }

    .mapboxgl-popup-content {
      padding: $space-sm;
    }
  }

  /**
   * Map Toggle (mobile only)
   */
  .map-toggle-wrap {
    align-items: center;
    background: $grey-light;
    bottom: 0;
    display: flex;
    justify-content: center;
    padding: $space-sm;
    position: fixed;
    width: 100%;
    z-index: 500;

    // Hide on desktop
    @media screen and (min-width: $screen-lg) {
      display: none;
    }

    .map-toggle {
      box-shadow: 0 1px 1px 0 rgba(60,64,67,.08), 0 1px 3px 1px rgba(60,64,67,.16);
    }
  }
}

/**
 * Error View
 */
.container--error {
  padding: $space;
}
